<template>
  <v-list
    class="px-2"
    :lines="false"
    density="compact"
    open-strategy="single"
    nav
  >
    <template v-for="(item, i) in nav" :key="i">
      <NavGroup v-if="isGroup(item)" :item="item" />
      <NavItem v-else :item="item" />
    </template>
  </v-list>
</template>

<script lang="ts">
import type { RouteLocationRaw } from "vue-router";
import { isGroup, NavItem, NavGroup } from "../private/app-navigation";

export default defineComponent({
  components: { NavItem, NavGroup },
  setup() {
    const router = useRouter();
    const config = useAppConfig();
    const nav = computed(() => config.app.settingsNav);

    const routeTo = (to: RouteLocationRaw) => {
      router.push(to);
    };

    return {
      nav,
      routeTo,
    };
  },
  methods: {
    isGroup,
  },
});
</script>
